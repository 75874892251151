import axios from "axios"
import { IAssignmentConfiguration, IConfiguration, IInsertOrUpdateConfigurations } from "../types/assignments"
import { TMetric } from "../types/alerts"
import { IColumnSorting } from "../types/stores/settings"
import { stringCapitalize } from "../utility/strings"
import { buildOdataUrl } from "../apps/assignments/utility/odata"
import moment from "moment"
import { IColumnFilter } from "../apps/assignments/types/odata"
import { TAssignment } from "../apps/assignments/types/assignments"


export const getAssignmentConfigurations = async () => {
    const url = `${process.env.REACT_APP_SERVER_URL}/AssignmentsConfiguration`
    return axios.get(url).then((res)=>res.data as IAssignmentConfiguration[])
}
export const getAssignnmentMetrics = async (productId:string,metricSortings:IColumnSorting[])=>{
    const sortingsQuery = metricSortings.length === 0 ? "" : `?$orderby=${metricSortings.map((sorting)=>`${sorting.innerClass ? sorting.innerClass + '.' : ''}${sorting.key} ${sorting.direction}`)}`
    const url = `${process.env.REACT_APP_SERVER_URL}/AssignmentsAndNotificationConfiguration/ConfigurationWithHierarchy/${productId}${sortingsQuery}`
    return axios.get(url).then((res)=>res.data as TMetric[])
}

export const getAssignmentConfiguration = async (id:number)=>{
    const url = `${process.env.REACT_APP_SERVER_URL}/AssignmentsConfiguration/Single/${id}`
    return axios.get(url).then((res)=>res.data as IConfiguration)
}

export const addMetricAssignmentConfigurationBulk = async (cfg:IConfiguration[])=>{
    const url = `${process.env.REACT_APP_SERVER_URL}/AssignmentsAndNotificationConfiguration/Bulk`    
    return axios.post(url,cfg)
}
export const updateMetricAssignmentConfigurationBulk = async (cfg:IConfiguration[])=>{
    const url = `${process.env.REACT_APP_SERVER_URL}/AssignmentsAndNotificationConfiguration/Bulk`    
    return axios.put(url,cfg)
}

export const insertOrUpdateAssignmentConfigurationBulk = async (cfg:IInsertOrUpdateConfigurations)=>{
    const url = `${process.env.REACT_APP_SERVER_URL}/AssignmentsAndNotificationConfiguration/UpsertBulk`    
    return axios.put(url,cfg)
}

export const deleteAssignmentConfiguration = async (cfg:IAssignmentConfiguration)=>{
    const url = `${process.env.REACT_APP_SERVER_URL}/AssignmentsConfiguration/${cfg.Id}`    
    return axios.delete(url)
}
export const exportAssignmentsExcel = async (controllerName: string, filters: IColumnFilter<TAssignment>[]) => {
    const url = buildOdataUrl(`${controllerName}/Download`, 0, 1000, filters, [], false, '', false)
    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    }
    try {
        const response = await axios.get(url, { headers, responseType: 'blob' })
        const fileDownload = require('js-file-download');
        await fileDownload(response.data, `Assegnazioni-${moment(new Date()).format("yyyyMMDD-hhmmss")}.xlsx`)
    }
    catch (error: any) {
        console.log(error);
    }
}