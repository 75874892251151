import React from 'react'
import { useSidebars } from '../../store/hooks/useSidebars'
export const DetailsContainer: React.FC<{ children: React.ReactNode, fullWidth?: boolean }> = ({ children, fullWidth }) => {
    const {isMobile} = useSidebars();
    const width = React.useMemo(() => { 
        if(fullWidth){
            return isMobile ? "100%" : "calc(100% - 1rem)"
        }
        return isMobile ? 'calc(100vw - 40px)' : '75%' 
    }, [fullWidth, isMobile])
    return <div
        className="relative border border-black bg-gray-100 border-solid shadow rounded p-2"
        style={{ width,minWidth:300 }}>
        {children}
    </div>

}