import React from 'react'
import { topbarHeight } from '../Topbar/Topbar'
import { useIsMobile } from '../../../utility/useIsMobile'
import { IconButton } from '@mui/material'
import { AiFillCaretLeft, AiFillCaretRight } from 'react-icons/ai'
import { sidebarsContext } from '../../../store/sidebars'
type Props = {
    children?: React.ReactNode
}


export const Leftbar: React.FC<Props> = ({ children }) => {
    const {isMobile,sidebarWidth} = useIsMobile();
    const {state,dispatch} = React.useContext(sidebarsContext)
    if(isMobile){
        return null;
    }

    const sidebarIsOpen = state.sidebarWidth && state.sidebarWidth > 0
    
        return <div className="fixed flex flex-col justify-start items-center top-[0px] left-[0px] bg-white text-white"
        style={{
            marginTop: topbarHeight,
            paddingTop:5,
            height: `calc(100vh - ${topbarHeight}px)`,
            width: state.sidebarWidth,
            borderRight:'1px solid lightgrey',
            transition:'all 0.25s ease'
        }}>
        {sidebarIsOpen && children}
        <IconButton
        className='hover:!bg-transparent !bg-transparent'
            style={{position:'absolute',top:'50vh',right:sidebarIsOpen ? 0 : -35,width:40,zIndex:99999999}}
            onClick={()=>dispatch({type:"SET_STATE",payload:{...state,sidebarWidth:sidebarIsOpen ? 0 : sidebarWidth}})}
        >
            {sidebarIsOpen ? <AiFillCaretLeft/> : <AiFillCaretRight/>}
        </IconButton>
    </div>
}