import React from "react";
import { useSidebars } from "../../store/hooks/useSidebars";
import { useUserContext } from "../../../../store/hooks/useUserContext";
import { AssignmentStatusEnum, EticketSourceTypeEnum, IAssignmentConfiguration, TAssignment } from "../../types/assignments";
import { paginationContext, PaginationEnum } from "../../store/pager";
import { getAssignmentConfigurations, getAssignmentFeedback, resolveAssignment } from "../../services/assignments";
import { intesa_green } from "../../../../themes/themes";
import { Button, Dialog, FormControl, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Snackbar, Typography } from "@mui/material";
import { getSuperMetricConfiguration } from "../../services/supermetric";
import { IAssignmentFeedback, ISuperMetricConfiguration } from "../../types/etickets";
import { DetailsContainer } from "../DetailsContainer/DetailsContainer";
import { LightTooltip } from "../LightTooltip/LightTooltip";
import { ICustomClaims } from "../../services/user";
import { SuperMetricDetailToolbar } from "./SuperMetricDetailToolbar/SuperMetricDetailToolbar";
import { MetricDetailToolbar } from "./MetricDetailToolbar/MetricDetailToolbar";
type Props = {
    assignment: TAssignment
    setAssignment:(x:TAssignment)=>void
    fullWidth?: boolean
}
export const AssignmentDetailToolbar: React.FC<Props> = ({ assignment, fullWidth, setAssignment }) => {
    if(assignment.SourceType === EticketSourceTypeEnum.SuperMetric){
        return <SuperMetricDetailToolbar assignment={assignment} fullWidth={fullWidth} setAssignment={setAssignment}/>
    }
    if(assignment.SourceType === EticketSourceTypeEnum.Metric){
        return <MetricDetailToolbar assignment={assignment} fullWidth={fullWidth} setAssignment={setAssignment}/>
    }

    return null;
}