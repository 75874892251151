import { IconButton, OutlinedInput } from '@mui/material'
import React from 'react'
import { HeaderFilterProps } from '../../types'
import { MdMore, MdOutlineRemove } from 'react-icons/md'
import { GoPlus } from 'react-icons/go'
import { HeaderFilterCellFormControl } from '../HeaderFilterCellFormControl/HeaderFilterCellFormControl'
import styled from '@emotion/styled'
import { useHeaderCell } from '../../hooks/useHeaderCell'
const StyledOutlinedInput = styled(OutlinedInput)({
    borderRadius: 0,
    border:'1px solid lightgray'
})
export const TextHeaderFilterCell: React.FC<HeaderFilterProps> = ({
    header, fullWidth, hideIcon, localValue: paramValue,enumStoreKey
}) => {
    const {setHeaderFilter,headerCurrentFilter,removeHeaderFilter,isLoading} = useHeaderCell({header,enumStoreKey})
    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement | HTMLInputElement> = (e) => {
        if (e.key === "Enter") {
            setHeaderFilter(header, localFilter);
        }
    }
    // const value = headerCurrentFilter? headerCurrentFilter.value : localFilter[header.id as string]
    const [localFilter, setLocalFilter] = React.useState<string | undefined>(headerCurrentFilter ? headerCurrentFilter.value : '')
    const inputValue = paramValue !== undefined ? paramValue.value : localFilter
    const setInputValue = paramValue !== undefined ? paramValue.setValue : setLocalFilter
    React.useEffect(() => setLocalFilter(headerCurrentFilter?.value), [headerCurrentFilter])
    return <><HeaderFilterCellFormControl
        header={header}
        input={<StyledOutlinedInput
        className='no-drag'
            fullWidth={fullWidth}
            onContextMenu={(e) => {
                e.preventDefault();
                removeHeaderFilter(header)
            }}
            id={header.label}
            disabled={isLoading}
            onKeyDown={handleKeyDown}
            label={header.label}
            onChange={(e) => setInputValue(e.target.value)}
            // value={localFilter[header.id as string]}
            value={inputValue ? inputValue : ''}
            endAdornment={!hideIcon && <div className="flex">
                <IconButton
                    className='hover:!bg-transparent'
                    disabled={isLoading}
                    onClick={() => setHeaderFilter(header, localFilter)}>
                    <GoPlus />
                </IconButton>

            </div>}
        />} />
    </>
}