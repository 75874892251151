import React from "react"
import { ISortableHeader } from "../../../types/general"

export type ODataProps = {
    headers: ISortableHeader<any>[],
}
export interface IContext {
    hiddenHeadersWidth: string
    resizedHeadersWidth: string
    visibleHeaders: ISortableHeader<any>[]
    headerMaxWidth: (h: ISortableHeader<any>, hiddenHeadersWidth: string, resizedHeaderWidth: string, removeScrollbar: boolean) => string | undefined
    headerWidth: (h: ISortableHeader<any>, hiddenHeadersWidth: string, resizedHeaderWidth: string, removeScrollbar: boolean, isLast: boolean) => string | undefined

}
export const scrollbarWidth = "6px";
// export const headerWidth = (h: ISortableHeader<any>,hiddenHeadersWidth:string,resizedHeaderWidth:string, removeScrollbar: boolean,isLast:boolean) => {
//     // console.log(h,isLast ? undefined : `calc(${h.width} - ${resizedHeaderWidth} + ${hiddenHeadersWidth} + ${h.resize ?? 0}px - ${removeScrollbar ? scrollbarWidth : "0px"})`,
//     //     hiddenHeadersWidth,
//     //     resizedHeaderWidth
//     // )
//     return isLast ? undefined : `calc(${h.width} - ${resizedHeaderWidth} + ${h.resize ?? 0}px - ${removeScrollbar ? scrollbarWidth : "0px"})`
// }
// export const headerMaxWidth = (h: ISortableHeader<any>,hiddenHeadersWidth:string,resizedHeaderWidth:string,  isLast: boolean) => isLast ? undefined : `calc(${h.width} - ${resizedHeaderWidth}  + ${h.resize ?? 0}px)`
export const useODataGrid: (props: ODataProps) => IContext = ({ headers }) => {
    const hiddenHeaders = headers.filter((h) => !h.isVisible)
    const visibleHeaders = headers.filter((h) => h.isVisible)
    const perc_headers = visibleHeaders.filter((h) => h.width.toString().includes("%"))
    const perc_widths = perc_headers.map((x) => x.width.toString().substring(0, x.width.toString().length - 1)).reduce((a, b) => a + parseFloat(b), 0)
    const static_headers = visibleHeaders.filter((h) => h.width.toString().includes("px"));
    const static_widths = static_headers.map((x) => x.width.toString().substring(0, x.width.toString().length - 2)).reduce((a, b) => a + parseFloat(b), 0)
    const overflow = perc_widths - 100;
    const overflow_remove = overflow > 0 ? overflow / visibleHeaders.length : 0;
    const headerWidth = (h: ISortableHeader<any>, hiddenHeadersWidth: string, resizedHeaderWidth: string, removeScrollbar: boolean, isLast: boolean) => {
        return isLast ? undefined : `calc(${h.width} - ${resizedHeaderWidth} - ${overflow_remove}% + ${h.resize ?? 0}px - ${removeScrollbar ? scrollbarWidth : "0px"})`
    }
    const headerMaxWidth = (h: ISortableHeader<any>, hiddenHeadersWidth: string, resizedHeaderWidth: string, isLast: boolean) => isLast ? undefined : `calc(${h.width} - ${resizedHeaderWidth} - ${overflow_remove}%  + ${h.resize ?? 0}px)`
    const hiddenHeadersWidth = React.useMemo(() => {

        if (hiddenHeaders.length === 0) {
            return "0px"
        }
        const hidden_perc_headers = hiddenHeaders.filter((h) => h.width.toString().includes("%"))
        const hidden_perc_widths = hidden_perc_headers.map((x) => x.width.toString().substring(0, x.width.toString().length - 1)).reduce((a, b) => a + parseFloat(b), 0)
        const hidden_static_headers = hiddenHeaders.filter((h) => h.width.toString().includes("px"));
        const hidden_static_widths = hidden_static_headers.map((x) => x.width.toString().substring(0, x.width.toString().length - 2)).reduce((a, b) => a + parseFloat(b), 0)
        if (hidden_perc_headers.length > 0 && hidden_static_headers.length > 0) {
            return `calc(${hidden_perc_widths / visibleHeaders.length}% + ${hidden_static_widths / visibleHeaders.length}px)`
        }
        if (hidden_perc_headers.length > 0) {
            return `calc(${hidden_perc_widths / visibleHeaders.length}%)`
        }
        if (hidden_static_headers.length > 0) {
            return `calc(${hidden_static_widths / visibleHeaders.length}%)`
        }
        return "0px";
    }, [headers])
    const resizedHeadersWidth = React.useMemo(() => {
        const resizedHeaders = headers.filter((h) => h.resize)
        const visibleHeaders = headers.filter((h) => h.isVisible)
        if (resizedHeaders.length === 0) {
            return "0px"
        }
        const resizeSum = resizedHeaders.map((x) => x.resize ?? 0).reduce((a, b) => a + b, 0)
        if (resizeSum > 0) {
            return `calc(${resizeSum / visibleHeaders.length}px)`
        }
        return "0px";
    }, [headers])

    return {
        hiddenHeadersWidth,
        resizedHeadersWidth,
        visibleHeaders,
        headerMaxWidth,
        headerWidth
    }
}