import React from "react";
import {paginationContext, PaginationEnum} from "../../../../store/pager";
import {Button, CircularProgress, IconButton, Snackbar} from "@mui/material";
import {LightTooltip} from "../../../LightTooltip/LightTooltip";
import { RiFileExcel2Line } from "react-icons/ri";
import { ASSIGNMENTS_CONTROLLER } from "../../../../services/assignments";
import { exportAssignmentsExcel } from "../../../../../../services/assignments";
export type ExportFilteredGridButtonProps = {
  enumKey: PaginationEnum;
};
export const ExportExcelGridButton: React.FC<
  ExportFilteredGridButtonProps
> = ({enumKey}) => {
  const {state} = React.useContext(paginationContext);
  const [isLoading,setIsLoading] = React.useState(false);
  const {filters} = state.pagination[enumKey];
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const controllerName = React.useMemo(()=>{
    switch(enumKey){
      case PaginationEnum.ASSIGNMENTS:return ASSIGNMENTS_CONTROLLER;
      default:return ASSIGNMENTS_CONTROLLER;
    }
  },[enumKey])
  if(enumKey !== PaginationEnum.ASSIGNMENTS){
    return null;
  }
  return (
    <>
      <LightTooltip title={<p className='text-xl'>Esporta Excel</p>}>
        <IconButton
          onClick={async() => {
            setIsLoading(true)
            setShowSnackbar(true)
            exportAssignmentsExcel(controllerName,filters)
            .finally(()=>setIsLoading(false))
          }}
        >
          <RiFileExcel2Line />
        </IconButton>
      </LightTooltip>
      <Snackbar
        open={showSnackbar}
        message={isLoading ? "Esportazione excel in corso" : "Esportazione excel completata"}
        action={isLoading ? <CircularProgress/> : <Button variant="contained" onClick={()=>setShowSnackbar(false)}>Chiudi</Button>}
      />
    </>
  );
};
