import React from "react";
import {IPile} from "../../store/hooks/usePiles";
import {Pile} from "../../components/Pile/Pile";
export type PilesContainerProps = {
  sidebarWidth: string | number;
  piles: IPile[];
  inner?: boolean;
};
export const PilesContainer: React.FC<PilesContainerProps> = ({
  piles,
  inner,
}) => {
  return (
    <div
      className="flex flex-row flex-wrap items-center bg-gray-100"
      style={{
        boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
        // width: 'calc(100% - 20px)',
        // marginLeft:5,
        padding: '2px 0px 2px 0px',
        // marginTop:2
      }}
    >
      {piles.map((pile) => (
        <Pile key={pile.key} pile={pile} />
      ))}
    </div>
  );
};
