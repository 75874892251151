import { IconButton } from "@mui/material";
import React from "react";
import { CgDetailsMore } from "react-icons/cg";
import { AssignmentForceClosureButton } from "../../../../AssignmentForceClosureButton/AssignmentForceClosureButton";
import { assignments_known_urls } from "../../../../../types/userStore";
import { useNavigate } from "react-router-dom";
import { TAssignment } from "../../../../../types/assignments";
type Props = {
    assignment:TAssignment
}
export const AssignmentIdCell:React.FC<Props> = ({assignment})=>{
    const navigate = useNavigate();
    return <div className="flex flex-col justify-stretch w-[100%]">
                <p className="text-center w-[100%] text-lg">{assignment.Id}</p>
                <div className="flex justify-between w-[100%] px-2 items-baseline">
                    <IconButton
                        style={{ padding: 0, margin: 0, width: 30, height: 30,borderRadius:2 }}
                        className="hover:!bg-gray-100"
                        onClick={() => {
                            navigate(`${assignments_known_urls.assignment_detail}?id=${assignment.Id}`,)
                        }}
                    >
                        <CgDetailsMore />
                    </IconButton>
                    <AssignmentForceClosureButton assignment={assignment} />
                </div>
            </div>
}