import React from 'react'
import { ISortableHeader } from '../../../types/general'
import { Button, Grid, IconButton, Modal, Paper } from '@mui/material'
import { useIsMobile } from '../../../utility/useIsMobile'
import { HeaderFilterCell } from '../HeaderFilterCell/HeaderFilterCell'
import { paginationContext, PaginationEnum } from '../../../store/pager'
import { HeaderFilterPopupProps } from '../types'
import { ODataFilterOperators, ODataValueTypes } from '../../../types/odata'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FilterChips } from '../../FilterChips/FilterChips'
import { Spacer } from '../../Spacer/Spacer'
import Draggable from 'react-draggable'
import { HeaderPopupFilterInput } from '../HeaderPopupFilterInput/HeaderPopupFilterInput'

export const HeaderFilterPopup: React.FC<HeaderFilterPopupProps> = ({
    header, open, onClose, enumStoreKey }) => {
    const { state, dispatch } = React.useContext(paginationContext);
    const { isMobile } = useIsMobile();
    const [currentValues, setCurrentValues] = React.useState<{ [key: string]: any }>({})
    const columns = [...(header.linkedColumns ?? []), header.id];
    const [selectedOperator, setSelectedOperator] = React.useState<{ [key: string]: ODataFilterOperators }>(Object.fromEntries(columns.map((col) => [col, 'eq'])))
    const mapHeaderFromColumn = (header: ISortableHeader<any>, id: string) => ({ ...header, id, label: id, defaultFilterOperator: selectedOperator[id] ?? header.defaultFilterOperator })

    const setFilters = () => {
        let local_filters = state.pagination[enumStoreKey].filters;
        let local_presets = state.pagination[enumStoreKey].filtersPresets;
        Object.entries(currentValues).forEach(([columnName, value]) => {
            local_presets = local_presets.filter((p) => p.filter.columnName !== columnName) //rimuovo i preset per il filtro che andrò ad applicare
            if (local_filters.find((f) => f.columnName === columnName)) { //sostituisco filtro esistente
                console.log("Replacing " + columnName)
                local_filters = local_filters.map((f) => f.columnName === columnName ? { ...f, value, columnName, operator: selectedOperator[columnName] } : f)
            }
            else {
                console.log("Adding " + columnName)
                local_filters = [...local_filters, {
                    columnName,
                    value,
                    operator: selectedOperator[columnName],
                    valueType: header.valueType,
                    nestedColumn: header.nestedColumn
                }]
            }
        })
        dispatch({
            type: "SET_FILTERS_AND_PRESETS",
            payload: {
                key: enumStoreKey,
                presets: local_presets,
                filters: local_filters
            }
        })
        onClose()
    }

    // return <Modal open={open} onClose={onClose}
    //     
    return open ? <Draggable axis='both' handle='.modal-container'>
        <div className='modal-container' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '300vw',
            height: '300vh',
            position: 'fixed',
            left: '-100vw',
            top: '-100vh',
            backgroundColor: 'rgba(0,0,0,0.4)',
            zIndex: 100
        }}
            onClick={onClose}>
            < Paper
                onClick={(e) => e.stopPropagation()}
                elevation={5}
                style={{
                    zIndex: 101,
                    paddingTop: 60,
                    paddingBottom: 60,
                    maxWidth: '80vw',
                    maxHeight: '90vh',
                    position: 'relative'
                }}
                className='!bg-gray-100 p-2 shadow-lg flex flex-col'>
                <IconButton style={{ position: 'absolute', right: 5, top: 5, zIndex: 2 }} onClick={onClose}><AiFillCloseCircle /></IconButton>
                <p
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        zIndex: 1,
                        padding: '5px 10px',
                        width: '100%',
                        borderRadius: '10px 10px 0px 0px',
                        borderBottom: '1px solid black'
                    }}
                    className='text-2xl m-0 h-[50px] bg-gray-200'>
                    Filtro {header.label}
                </p>
                <Spacer direction='y' spacing={2} />
                <FilterChips enumKey={enumStoreKey} />
                <Spacer direction='y' spacing={2} />
                <div
                    style={{ padding: 10, maxWidth: '100%' }}
                    className='flex flex-row flex-wrap overflow-auto'>
                    {columns.map((id) => <HeaderPopupFilterInput
                        filterValueType={header.valueType}
                        enumStoreKey={enumStoreKey}
                        header={mapHeaderFromColumn(header, id.toString())}
                        selectedOperator={selectedOperator}
                        setSelectedOperator={setSelectedOperator}
                        currentValues={currentValues}
                        setCurrentValues={setCurrentValues}
                    />)}
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: 0, height: 50, width: '100%', padding: '5px 10px' }} className='flex justify-end'>
                    <Button
                        onClick={setFilters}
                        variant='contained'
                    >
                        Applica
                    </Button>
                </div>
            </Paper>
        </div>
    </Draggable > : null;
}