import React from "react";
import {
  IPile,
  PileContainerEnum,
  PileSetEnum,
  usePiles,
} from "../../store/hooks/usePiles";
import { Page } from "../../components/Page/Page";
import { useIsMobile } from "../../utility/useIsMobile";
import { Button, ButtonProps, Grid, styled } from "@mui/material";
import { PilesContainer } from "./PilesContainer";
import { topbarHeight } from "../../components/Page/Topbar/Topbar";
const HistoryButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: "black",
  backgroundColor: "lightgrey",
  "&:hover": {
    backgroundColor: "grey",
  },
}));
export const Home: React.FC = () => {
  const { pileSets, state_presets } = usePiles();
  const [pileSetKey, setPileSetKey] = React.useState(PileSetEnum.attivi);
  const { isMobile, sidebarWidth } = useIsMobile();
  const innerContainers = Array.from(
    new Set(
      pileSets[pileSetKey]
        .filter((p) => p.innerContainerName)
        .map((x) => x.innerContainerName)
    )
  );
  console.log(pileSets)
  return (
    <Page>
      <div
        className="flex w-[100%] pb-20"
        style={{ flexDirection: isMobile ? "column" : "row" }}
      >
        <div
          style={{
            width: isMobile ? "calc(100%)" : "calc(100% - 100px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              position: isMobile ? "unset" : "fixed",
              marginTop:isMobile ? 10 : 'unset',
              right: 0,
              top: topbarHeight,
              height: isMobile ? 50 : "100%",
              width: isMobile ? "100%" : "100px",
              borderLeft: "1px solid lightgrey",
              flexDirection: isMobile ? "row" : "column"
            }}
            className="flex p-2 bg-white items-end"
          >
            <Button
              variant={
                pileSetKey === PileSetEnum.attivi ? "contained" : "outlined"
              }
              style={{ marginBottom: 10, width: isMobile ? 150 : "100%" }}
              onClick={() => setPileSetKey(PileSetEnum.attivi)}
            >
              Attivi
            </Button>
            {pileSetKey === PileSetEnum.storico ? (
              <HistoryButton
                style={{ marginBottom: 10, width: isMobile ? 150 : "100%" }}
                variant="contained"
                sx={{
                  cont: "grey",
                }}
                onClick={() => setPileSetKey(PileSetEnum.storico)}
              >
                Storico
              </HistoryButton>
            ) : (
              <Button
                style={{ marginBottom: 10, width: isMobile ? 150 : "100%" }}
                variant="outlined"
                sx={{
                  cont: "grey",
                }}
                onClick={() => setPileSetKey(PileSetEnum.storico)}
              >
                Storico
              </Button>
            )}
          </div>
            <Grid container spacing={0}>
              {Object.entries(PileContainerEnum).map(([key, containerName], i) =>
                pileSets[pileSetKey].filter(
                  (p) => p.containerName === containerName && !p.innerContainerName
                ).length > 0 ? (<Grid key={containerName} item xs={12} sm={6} style={{ borderRight: (isMobile || i % 2 === 1) ? 'unset' : '1px solid lightgrey' }}>
                  <p className="w-[100%] text-xl font-bold text-center">{containerName}</p>
                  {/* <ExpandableContainer
                    defaultOpen
                    textIsClickable
                    containerClassNames="flex flex-row ml-1 pl-1 h-[30px] items-center"
                    containerExtraStyles={{
                      borderBottom:
                        value === PileContainerEnum.tags
                          ? "unset"
                          : "1px solid lightgrey",
                      width: 'calc(100% - 4px)',
                    }}
                    text={value}
                    iconPosition="afterText"
                  > */}
                  <PilesContainer
                    piles={pileSets[pileSetKey].filter(
                      (p) =>
                        p.containerName === containerName && !p.innerContainerName
                    )}
                    sidebarWidth={sidebarWidth}
                  />
                  {/* </ExpandableContainer> */}
                  <Grid container>
                    <p className="w-[100%] text-xl font-bold text-center">Processi</p>
                    {Object.entries(PileContainerEnum).map(([key, value]) =>
                      innerContainers.length > 0 &&
                        pileSets[pileSetKey].filter(
                          (p) => p.containerName === value && p.innerContainerName
                        ).length > 0 ? innerContainers.map((ic) => (
                          <Grid item xs={6} sm={6}>
                            {/* <ExpandableContainer
                            defaultOpen
                            textIsClickable
                            containerClassNames="flex flex-row pl-1 ml-1 h-[30px] items-center"
                            containerExtraStyles={{
                              borderBottom: "1px solid lightgrey",
                              width: 'calc(100% - 4px)',
                            }}
                            text={ic ?? ""}
                            iconPosition="afterText"
                          > */}
                            <PilesContainer
                              inner
                              sidebarWidth={sidebarWidth}
                              piles={pileSets[pileSetKey].filter(
                                (p) =>
                                  p.containerName === value &&
                                  p.innerContainerName &&
                                  p.innerContainerName === ic
                              ).map((pile: IPile) => ({
                                ...pile,
                                filterPresets: containerName === PileContainerEnum.personal ? [...pile.filterPresets, state_presets.ASSIGNMENTS.MY_ASSIGNMENTS] : pile.filterPresets
                              })
                              )
                              }
                            />
                            {/* </ExpandableContainer> */}
                          </Grid>
                        )) : null
                    )}
                  </Grid>
                </Grid>
                ) : null
              )}
          </Grid>
        </div>

      </div>
    </Page>
  );
};
