import React from 'react'
import { ISuperMetricConfiguration } from '../../../types/etickets'
import { Button, Dialog, FormControl, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Snackbar, Typography } from '@mui/material'
import { AssignmentStatusEnum, IAssignmentConfiguration, TAssignment } from '../../../types/assignments'
import { getAssignmentConfigurations, resolveAssignment } from '../../../services/assignments'
import { getSuperMetricConfiguration } from '../../../services/supermetric'
import { useSidebars } from '../../../store/hooks/useSidebars'
import { intesa_green } from '../../../../../themes/themes'
import { useUserContext } from '../../../../../store/hooks/useUserContext'
import { ICustomClaims } from '../../../services/user'
import { paginationContext, PaginationEnum } from '../../../store/pager'
import { LightTooltip } from '../../LightTooltip/LightTooltip'
import { DetailsContainer } from '../../DetailsContainer/DetailsContainer'
type Props = {
    assignment: TAssignment
    fullWidth?: boolean
    setAssignment:(x:TAssignment)=>void

}
export const MetricDetailToolbar: React.FC<Props> = ({ assignment,fullWidth,setAssignment }) => {
    const { isMobile } = useSidebars();
    const { account, userClaims } = useUserContext();
    const sbhAdminClaim: ICustomClaims = { ProductId: "sbh", RuoloId: 'admin' }
    const isAdmin = userClaims.find((x) => x.ProductId === sbhAdminClaim.ProductId && x.RuoloId === sbhAdminClaim.RuoloId) !== undefined;
    const [openConfirm, setOpenConfirm] = React.useState(false)
    const [tooltipDescr, setTooltipDescr] = React.useState('');
    const notResolvableReasons = {
        NotOwner: {
            val: account.username.toLowerCase() !== assignment.OwnerEmail.toLowerCase(),
            descr: "Solo l'owner può risolvere l'assegnazione"
        },
        AlreadyResolved: {
            val: assignment.Status === AssignmentStatusEnum.Resolved,
            descr: "Assegnazione già risolta"
        }
    }
    const notResolvable = React.useMemo(() => {
        const reasons = Object.values(notResolvableReasons);
        let tooltips: string[] = []
        let result = false;
        for (let i = 0; i < reasons.length; i++) {
            if (reasons[i].val) {
                tooltips.push(reasons[i].descr)
                result = true;
            }
        }
        setTooltipDescr(tooltips.join("\n"))
        return result;
    }, [assignment, account])
    // const notResolvable = React.useMemo(() => assignment.Status === AssignmentStatusEnum.Resolved, [assignment, account])
    const { state: paginationState, dispatch: paginationDispatch } = React.useContext(paginationContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [assignmentConfiguration, setAssignmentConfiguration] = React.useState<IAssignmentConfiguration>()
    
    React.useEffect(() => {
        getAssignmentConfigurations(assignment.ExternalReferenceId)
                .then((cfgs) => 
                    setAssignmentConfiguration(cfgs.find((cfg) => cfg.OwnerMail === assignment.OwnerEmail))
                )
                .catch((err) => console.log(err))
        
    }, [assignment.OwnerEmail, assignment.ExternalReferenceId])
    
    const [reason, setReason] = React.useState<string | null | undefined>(assignmentConfiguration?.ClosureReason ? assignmentConfiguration.ClosureReason[0] : null)
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setReason(e.target.value)
    }

    const handleClick = () => {
        if (reason) {
            setIsLoading(true);
            resolveAssignment(assignment.Id, reason)
                .then((res) => {
                    setAssignment(res)
                    setOpenSnackBar(true)
                })
                .finally(() => setIsLoading(false))

        }
    }
    const handleAdminBtnClick = () => {
        setIsLoading(true);
        const adminClosureReason = "Chiuso da admin"
        resolveAssignment(assignment.Id, adminClosureReason)
            .then((res) => {
                setAssignment(res)
                setOpenSnackBar(true)
                setOpenConfirm(false)
            })
            .finally(() => setIsLoading(false))
    }
    const [openSnackbar, setOpenSnackBar] = React.useState(false);
    const label = "Motivazione Chiusura"
    const tooltipWrap = (children: React.ReactElement) => tooltipDescr ? <LightTooltip arrow title={tooltipDescr} >{children}</LightTooltip> : children
    if (isLoading) {
        return <LinearProgress style={{ color: intesa_green, width: 100, height: 10, marginTop: 20 }} />
    }
    if (!assignmentConfiguration) {
        return <p className='text-lg text-center'>Nessuna closure reason configurata.</p>
    }
    return <DetailsContainer fullWidth={fullWidth}>
        {assignmentConfiguration && <div style={{ flexDirection: isMobile ? 'column' : 'row', display: 'flex' }}>
            <p className='text-2xl'>
                {assignmentConfiguration && assignmentConfiguration.IsAutomaticClose ?
                    `Se ritieni che questa segnalazione sia già stata gestita o non sia rilevante/corretta ti chiediamo di risolvere il caso ${assignmentConfiguration.ClosureReason.length == 0 ? "inserendo un commento" : "selezionando una risposta"}.`
                    :
                    `Questa assegnazione si chiuderà in automatico quando il problema che l'ha generata sarà risolto. Puoi lasciare un feedback ${assignmentConfiguration && assignmentConfiguration.ClosureReason.length == 0 ? "inserendo un commento" : "selezionando una risposta"}.`
                }
            </p>
            <div className='flex items-center justify-end' style={{ marginTop: 10, }}>
                <FormControl variant="outlined" style={{ minWidth: 200, height: 60 }}>
                    <InputLabel htmlFor={"supermetricdetail-toolbar-select"}>{label}</InputLabel>
                    {assignmentConfiguration.ClosureReason.length === 0
                    ?
                        tooltipWrap(<OutlinedInput
                            disabled={isLoading || notResolvable}
                            value={reason} style={{ height: 60, backgroundColor: 'white', border: '1px solid rgb(200,200,200)', borderRadius: 0 }} id={"supermetricdetail-toolbar-select"} label={label} onChange={handleChange} />)
                        :
                        tooltipWrap(<Select
                            disabled={isLoading || notResolvable}
                            id="supermetricdetail-toolbar-select"
                            label={label}
                            input={<OutlinedInput style={{ height: 60, backgroundColor: 'white', border: '1px solid rgb(200,200,200)', borderRadius: 0 }} id={"supermetricdetail-toolbar-select"} label={label} onChange={handleChange} />}

                        >
                            {assignmentConfiguration.ClosureReason.map((feedback) => <MenuItem value={feedback}>{feedback}</MenuItem>)}
                        </Select>)
                    }
                </FormControl>
                <div className='ml-4' />

                <Button
                    style={{ height: 60, width: 120, borderRadius: 0 }}
                    variant='contained'
                    disabled={!reason || isLoading || notResolvable}
                    onClick={handleClick}
                >
                    Invia
                </Button>
                <div className="ml-4" />
                <Button
                    style={{ height: 60, width: 120, borderRadius: 0 }}
                    variant='contained'
                    disabled={!isAdmin || isLoading}
                    onClick={() => setOpenConfirm(true)}
                >
                    Forza Chiusura
                </Button>
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={6000}
                    onClose={() => setOpenSnackBar(false)}
                    message={`Segnalazione risolta correttamente`}
                />
            </div>
            <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
                <div className="flex flex-col min-h-[150px] p-2">
                    <Typography>Sei sicuro di voler forzare la chiusura?</Typography>
                    <div className="mt-auto" />
                    <div className="flex w-[100%] justify-between">
                        <Button variant="outlined" onClick={() => setOpenConfirm(false)}>Annulla</Button>
                        <Button variant="contained" onClick={handleAdminBtnClick}>Conferma</Button>
                    </div>
                </div>
            </Dialog>
        </div>}
    </DetailsContainer>
    {/* </div> */ }

}