import React from 'react'
import { IInputEvent } from '../../../types/eventNotifications';
import { DataMonitorCard } from './InputEventsCard/DataMonitorCard';
import { ODataGridRow } from '../../ODataGrid/ODataGridRow/ODataGridRow';
import { GridRowProps } from '../../../types/odata';
export const InputEventsGridRow: React.FC<GridRowProps<IInputEvent>> = ({
    item, headers, enumStoreKey
}) => {
    const defaultContainerClassNames = "hover:!border-intesa-green hover:!bg-gray-300 transition-colors duration-100 hover:!text-black"

    return <ODataGridRow
        itemContainerCustomClassNames={(x) => !x.IsActive
            ? `bg-white border-transparent ${defaultContainerClassNames}`
            : `bg-gray-100 border-gray-300 ${defaultContainerClassNames}`
        }
        item={item}
        headers={headers}
        enumStoreKey={enumStoreKey}
        customGridCard={<DataMonitorCard headers={headers} item={item} />}
    />
}