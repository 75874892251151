import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { it } from 'date-fns/locale'
import moment from "moment";
import { useReactDateRangePicker } from './hooks/useReactDateRangePicker';
import './styles/datepicker_styles.css'
import { Button } from '@mui/material';
    export const ReactDateRangePicker = ({  startRange, onClose, customSetFilters }) => {
    const {
        handleSelect,
        itStaticRanges,
        itInputRanges,
        range,
        handleConfirm
    } = useReactDateRangePicker({ startRange });
    return (
        <div className='flex flex-col p-2 bg-white items-center'>
            <DateRangePicker
                rangeColors={["#107c41", "#107c41", "#107c41"]}
                color={"#107c41"}
                locale={it}
                ranges={[range]}
                onChange={(range) => {
                    handleSelect(range);
                }}
                staticRanges={itStaticRanges}
                inputRanges={itInputRanges}
            />
            <div className="flex flex-row justify-between  w-[100%] p-2">
                <Button
                    variant='outlined'
                    onClick={onClose}
                >
                    Annulla
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        range.startDate = moment(range.startDate).utc();
                        range.endDate = moment(range.endDate).utc();
                        if (customSetFilters) {
                            customSetFilters(range);
                        } else{
                            alert("Custom Set Filter is not set")
                        }
                        onClose();
                    }}
                >
                    Conferma
                </Button>
            </div>
        </div>
    )
}