import { FormControl, InputLabel } from '@mui/material'
import React from 'react'
import { ISortableHeader } from '../../../../types/general'
type Props = { header: ISortableHeader<any>, input: React.ReactNode,hideLabel?:boolean,customStyles?:React.CSSProperties }
export const HeaderFilterCellFormControl: React.FC<Props> = ({ header, input,hideLabel,customStyles }) => {
    console.log(customStyles)
    return <FormControl fullWidth variant="outlined" style={{ margin: '0px 0px 2px 0px',height:55,...customStyles }}>
        {!hideLabel && <InputLabel htmlFor={header.label}>{header.label}</InputLabel>}
        {input}
    </FormControl>
}