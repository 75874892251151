import React from 'react'
import { Button, Grid, IconButton, Paper } from '@mui/material'
import { paginationContext } from '../../../store/pager'
import { ODataFilterOperators } from '../../../types/odata'
import { AiFillCloseCircle } from 'react-icons/ai'
import { FilterChips } from '../../FilterChips/FilterChips'
import { Spacer } from '../../Spacer/Spacer'
import Draggable from 'react-draggable'
import { AllHeaderFilterPopupProps } from '../types'
import { useHeaders } from '../../../store/hooks/useHeaders'
import { HeaderPopupFilterInput } from '../HeaderPopupFilterInput/HeaderPopupFilterInput'
import { useIsMobile } from '../../../utility/useIsMobile'
import { topbarHeight } from '../../Page/Topbar/Topbar'

export const AllHeadersFilterPopup: React.FC<AllHeaderFilterPopupProps> = ({
    open, onClose, enumStoreKey }) => {
    const { state, dispatch } = React.useContext(paginationContext);
    const [currentValues, setCurrentValues] = React.useState<{ [key: string]: any }>({})
    const { pageHeaders } = useHeaders();
    const {isMobile} = useIsMobile();
    const headers = pageHeaders[enumStoreKey];
    const columns = Array.from(new Set(headers.map((header) => [...(header.linkedColumns ?? []), header.id]).flat()));
    const [selectedOperators, setSelectedOperators] = React.useState<{ [key: string]: ODataFilterOperators }>(Object.fromEntries(columns.map((col) => [col, 'eq'])))
    const setFilters = () => {
        let local_filters = state.pagination[enumStoreKey].filters;
        let local_presets = state.pagination[enumStoreKey].filtersPresets;
        Object.entries(currentValues).forEach(([columnName, value]) => {
            local_presets = local_presets.filter((p) => p.filter.columnName !== columnName) //rimuovo i preset per il filtro che andrò ad applicare
            const header = headers.find((h) => h.id === columnName);
            if (header) {
                if (local_filters.find((f) => f.columnName === columnName)) { //sostituisco filtro esistente
                    console.log("Replacing " + columnName)
                    local_filters = local_filters.map((f) => f.columnName === columnName ? { ...f, value, columnName, operator: selectedOperators[columnName] } : f)
                }
                else {
                    console.log("Adding " + columnName)
                    local_filters = [...local_filters, {
                        columnName,
                        value,
                        operator: selectedOperators[columnName],
                        valueType: header.valueType,
                        nestedColumn: header.nestedColumn
                    }]
                }
            }
        })
        dispatch({
            type: "SET_FILTERS_AND_PRESETS",
            payload: {
                key: enumStoreKey,
                presets: local_presets,
                filters: local_filters
            }
        })
    }

    // return <Modal open={open} onClose={onClose}
    //     
    const getHeaderById = (id: string) => headers.find((h) => h.id === id);
    return open ? <Draggable axis='both' cancel='.no-drag' handle='.modal-container'>
        <div className='modal-container' style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '300vw',
            height: '300vh',
            position: 'fixed',
            left: '-100vw',
            top: '-100vh',
            backgroundColor: 'rgba(0,0,0,0.4)',
            zIndex: 999999999
        }}
            onClick={onClose}>
            < Paper
                onClick={(e) => e.stopPropagation()}
                elevation={5}
                style={{
                    zIndex: 101,
                    marginTop:isMobile ? 0 : topbarHeight,
                    paddingTop: 60,
                    paddingBottom: 60,
                    maxWidth: '95vw',
                    width:'100%',
                    maxHeight: isMobile ? '60vh':'85vh',
                    position: 'relative'
                }}
                className='!bg-gray-100 p-1  shadow-lg flex flex-col'>
                <IconButton style={{ position: 'absolute', right: 5, top: 5, zIndex: 2 }} onClick={onClose}><AiFillCloseCircle /></IconButton>
                <p
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        zIndex: 1,
                        padding: '5px 10px',
                        width: '100%',
                        borderRadius: '10px 10px 0px 0px',
                        borderBottom: '1px solid black'
                    }}
                    className='text-2xl m-0 h-[50px] bg-gray-200'>
                    Filtro {enumStoreKey}
                </p>
                <Spacer direction='y' spacing={2} />
                <FilterChips enumKey={enumStoreKey} />
                <Spacer direction='y' spacing={2} />
                <div
                    style={{ padding: 5, maxWidth: 'calc(100% - 10px)' }}
                    className='flex flex-row flex-wrap overflow-auto'>
                    <Grid container>
                        {columns.map((id) => <Grid item xs={12} sm={6} md={4}>
                            <HeaderPopupFilterInput
                            header={getHeaderById(id.toString()) ?? headers[0]}
                            filterValueType={getHeaderById(id.toString())?.valueType ?? headers[0].valueType}
                            selectedOperator={selectedOperators}
                            setSelectedOperator={setSelectedOperators}
                            enumStoreKey={enumStoreKey}
                            currentValues={currentValues}
                            setCurrentValues={setCurrentValues}

                        />
                        </Grid>
                        )}
                    </Grid>
                </div>
                <div style={{ position: 'absolute', bottom: 0, left: 0, height: 50, width: '100%', padding: '5px 10px' }} className='flex justify-end'>
                    <Button
                        onClick={setFilters}
                        variant='contained'
                    >
                        Applica
                    </Button>
                </div>
            </Paper>
        </div>
    </Draggable > : null;
}