import axios from "axios"
import { IColumnFilter, IColumnSorting, ODataResponse } from "../types/odata"
import { IAssignmentConfiguration, TAssignment } from "../types/assignments"
import { buildOdataUrl } from "../utility/odata"
import { AssignmentFeedbackTypeEnum, IAssignmentFeedback } from "../types/etickets"

export const ASSIGNMENTS_CONTROLLER = "Assignment"
export const getAssignments:()=>Promise<ODataResponse<TAssignment[]>> = async () => {
    const sortings:IColumnSorting<TAssignment>[] = [
        {
            key:"Id",
            direction:"desc",
            innerClass:""
        }
    ] 
    const url = buildOdataUrl(ASSIGNMENTS_CONTROLLER,0,0,[],sortings);
    return axios.get(url).then((res)=>res.data)
}

export const getPagedAssignments:(
    skip:number,
    top:number,
    sortings:IColumnSorting<TAssignment>[],
    filters:IColumnFilter<TAssignment>[],
    onlyCount?:boolean)=>Promise<ODataResponse<TAssignment[]>> = async (skip,top,sortings,filters,onlyCount) => {
    const url = buildOdataUrl(ASSIGNMENTS_CONTROLLER,skip,top,filters,sortings,onlyCount);
    return axios.get(url).then((res)=>{
        return res.data})
}

export const viewAssignment:(id:number)=>Promise<TAssignment> = async (id:number) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/${ASSIGNMENTS_CONTROLLER}/View/${id}`
    return axios.put(url).then((res)=>res.data)
}

export const unlockAssignment:(id:number)=>Promise<TAssignment> = async (id:number) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/${ASSIGNMENTS_CONTROLLER}/Unlock/${id}`
    return axios.put(url).then((res)=>res.data)
}

export const resolveAssignment:(id:number,closureReason:string)=>Promise<TAssignment> = async (id:number,closureReason:string) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/${ASSIGNMENTS_CONTROLLER}/Resolve`
    const payload = {
        id,
        closureReason
    }
    return axios.put(url,payload).then((res)=>res.data)
}

export const assignmentFeedback:(assignmentId:number,feedback:string,ttlDays:null|number,feedbackType:AssignmentFeedbackTypeEnum)=>Promise<TAssignment> = async (assignmentId:number,feedback:string,ttlDays:null|number,feedbackType?:AssignmentFeedbackTypeEnum) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/${ASSIGNMENTS_CONTROLLER}/Feedback`
    const payload = {
        assignmentId,
        feedback,
        ttlDays,
        feedbackType:feedbackType ?? "Closure"
    }
    return axios.put(url,payload).then((res)=>res.data)
}

export const getAssignmentConfigurations:(metricId:number)=>Promise<IAssignmentConfiguration[]> = async (metricId:number) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/AssignmentsConfiguration/SingleByMetricId/${metricId}`
    return axios.get(url).then((res)=>res.data)
}

export const getAssignmentFeedback:(metricId:number)=>Promise<IAssignmentFeedback[]> = async (superMetricId:number) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/${ASSIGNMENTS_CONTROLLER}/AllowedFeedback/${superMetricId}`
    return axios.get(url).then((res)=>res.data)
}

export const getSingleAssignment = async (id:number)=> {
    return axios.get(`${process.env.REACT_APP_SERVER_URL}/${ASSIGNMENTS_CONTROLLER}/Single/${id}`).then((res)=>res.data)
}