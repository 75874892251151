import React from 'react'
import { ISortableHeader } from '../../../../types/general'
import { Typography } from '@mui/material'
import { useHeaderCell } from '../../../HeaderCell/hooks/useHeaderCell'
import { PaginationEnum } from '../../../../store/pager'
type PROPS = {
    header: ISortableHeader<any>
    x: any
    isLoading: boolean,
    enumStoreKey: PaginationEnum
}
export const statusClassNames = (status: string) => {
    let _ = status.toLowerCase();
    switch (_) {
        case "active": return "!bg-gray-100 !text-black  !border !border-red-400 !p-1 !rounded !shadow leading-8 truncate"
        case "resolved": return "!bg-gray-100 !text-black  !border !p-1 !rounded !shadow leading-8 truncate"
        case "viewed": return "!bg-gray-200 !text-black !font-bold !border !p-1 !rounded !shadow leading-8 truncate"
        case "new": return "!bg-intesa-green !text-white !font-bold !border !p-1 !rounded !shadow align-baseline leading-8 truncate"
        default: return "text-black truncate"
    }
}
export const ODataGridRowCell: React.FC<PROPS> = ({ header, x, isLoading, enumStoreKey }) => {
    const {
        setHeaderFilter
    } = useHeaderCell({
        header, enumStoreKey
    })
    const getHeaderValue = (header: ISortableHeader<any>) => {
        if (header.selector) {
            return header.selector(x)
        }
        if (header.text) {
            return header.text(x)
        }
        return undefined;
    }
    const isStatusHeader = ["Stato"].includes(header.label);
    if (header.text)
        return <p
            onContextMenu={(e) => {
                e.preventDefault();
                setHeaderFilter(header, getHeaderValue(header))
            }}
            className={`text-center px-1 w-[100%] min-h-[30px] text-base ${isStatusHeader ? statusClassNames(x.Status) : ''}`
            }
        >
            {x ? header.text(x) : ""}
        </p>
    if (header.element)
        return x ? <>{header.element(x)}</> : null
    return null;
}