import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useIsMobile } from '../../utility/useIsMobile'
import moment from 'moment'
import { formatDate } from '../../utility/formatDate'
import { formatNumericValue } from '../../utility/formats'
type Props = { extraIcon?: React.ReactElement, noFormat?: boolean, extraClassnames?: string, bold?: boolean, value: string | number | null | undefined, align?: "center" | "right" | "left" | "inherit" | "justify" }
export const DetailsValue: React.FC<Props>
    = ({ bold, value, align, extraClassnames, noFormat, extraIcon }) => {
        const { isMobile } = useIsMobile();
        const isNumeric = (str: string) => {
            return !isNaN(Number(str))
        }
        const format = (value: any) => {
            switch (typeof (value)) {
                case "string":
                    if (moment(value, true).isValid()) {
                        return formatDate(value)
                    }
                    if (isNumeric(value)) {
                        return formatNumericValue(parseFloat(value), parseFloat(value) % 1 === 0 ? 0 : 2)
                    }
                    return (!value || value === "N/A") ? '' : value
                case "number":
                    return formatNumericValue(value, value % 1 === 0 ? 0 : 2)
                case "boolean":
                    return value ? "Sì" : "No"
                default:
                    return value ?? ''
            }
        }
        return <Grid item xs={8} sm={8} className='h-[100%] min-h-[20px] overflow-hidden' >
            <div className='flex w-[100%] items-center'>
                <Typography
                    variant='body1'
                    align={"left"}
                    style={{ wordBreak: 'break-all' }}
                    className={`${isMobile ? "!text-base" : "!text-2xl"} ${bold ? "!font-bold" : ""} w-[100%] pl-2 grow ${extraClassnames ?? ''}`}>
                    {noFormat ? value : format(value)}
                </Typography>
                {extraIcon}
            </div>
        </Grid>

    }