import React from 'react'
import { AssignmentStatusEnum, EticketSourceTypeEnum, TAssignment } from '../../types/assignments'
import { SiPowerbi } from "react-icons/si";
import { DetailsContainer } from '../DetailsContainer/DetailsContainer'
import { DetailsLabelValueContainer } from '../DetailsLabelValueContainer/DetailsLabelValueContainer'
import { DetailsValue } from '../DetailsValue/DetailsValue'
import { DetailsLabel } from '../DetailsLabel/DetailsLabel'
import { Status_Mapper } from '../../store/hooks/useHeaders'
import { DetailsTitle } from '../DetailsTitle/DetailsTitle'
import { assignments_known_urls } from '../../types/userStore'
import { DetailNavigatorIcon } from '../DetailNavigatorIcon/DetailNavigatorIcon'
import { Grid, LinearProgress, Typography } from '@mui/material'
import { statusClassNames } from '../ODataGrid/ODataGridRow/ODataGridRowCell/ODataGridRowCell'
import { intesa_green } from '../../../../themes/themes'
import { ExtendedSuperMetricValueDetailExpandableContainer } from '../SuperMetricValueDetail/ExtendedSuperMetricValueDetailExpandableContainer'
import { IExtendedSuperMetricValue } from '../../types/etickets'
import { useUserContext } from '../../../../store/hooks/useUserContext'
import { globalContext } from '../../store/globalStore'
import { getSuperMetricValue } from '../../services/supermetric'
import { APIError } from '../../types/general'
import { IExtendedAlert } from '../../types/alerts'
import { getExtendedAlert } from '../../services/alerts'
import { Link } from 'react-router-dom'
import { stringCapitalize } from '../../../../utility/strings'
import { ExtendedAlertDetailExpandableContainer } from '../AlertDetail/ExtendedAlertDetailExpandableContainer';
import { formatDate } from '../../utility/formatDate';
import { UnlockAssignmentBtn } from '../AssignmentsGrid/AssignmentRow/UnlockAssignmentBtn/UnlockAssignmentBtn';
type Props = {
    isLoading: boolean
    assignment: TAssignment
    setAssignment?: (x: TAssignment) => void
    fullWidth?: boolean
    showDetails?: boolean
}
export const AssignmentDetail: React.FC<Props> = (props) => {
    const { isLoading, assignment, fullWidth, setAssignment } = props
    const { authenticatedCall } = useUserContext();
    const [localIsLoading, setIsLoading] = React.useState(false);
    const { dispatch: globalDispatch } = React.useContext(globalContext);
    const [superMetricValue, setSuperMetricValue] = React.useState<IExtendedSuperMetricValue>();
    const [alert, setAlert] = React.useState<IExtendedAlert>()
    React.useEffect(() => {
        const _load = async () => {
            setIsLoading(true);
            if (assignment.SourceType === EticketSourceTypeEnum.Metric) {
                getExtendedAlert(assignment.ExternalEventId)
                    .then((res) => setAlert(res))
                    .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                    .finally(() => setIsLoading(false))
            } else {
                getSuperMetricValue(assignment.ExternalEventId)
                    .then((res) => setSuperMetricValue(res))
                    .catch((err: APIError) => globalDispatch({ type: "SET_ERROR", payload: { message: err.response.data.Message, details: err.toString() } }))
                    .finally(() => setIsLoading(false))
            }
        }
        authenticatedCall(_load);
    }, [authenticatedCall, globalDispatch, assignment])
    const custom_values = React.useMemo(() => {
        if (!superMetricValue) {
            return [];
        }
        const custom_value_link_key = "custom";
        const custom_report_link_key = "custom_link_report";
        return Object.entries(superMetricValue.Metadata).filter(([key, value]) => key.toLowerCase().includes(custom_value_link_key) && !key.toLowerCase().includes(custom_report_link_key)).map(([key, value]) => ({
            Name: stringCapitalize(key.substring(custom_value_link_key.length + 1).replaceAll("_", " ")),
            Value: `${value}`
        }))
    }, [superMetricValue])
    const reports_links = React.useMemo(() => {
        if (!superMetricValue) {
            return [];
        }
        const custom_report_link_key = "custom_link_report";
        return Object.entries(superMetricValue.Metadata).filter(([key, value]) => key.toLowerCase().includes(custom_report_link_key)).map(([key, value]) => ({
            Name: key.substring(custom_report_link_key.length + 1).replaceAll("_", " "),
            Link: value
        }))
    }, [superMetricValue])
    if (localIsLoading || isLoading || !assignment) {
        return <LinearProgress style={{ color: intesa_green, marginTop: 20, width: 100, height: 10 }} />
    }
    return <DetailsContainer fullWidth={fullWidth}>

        <DetailsTitle
            noBorder
            text={`Assegnazione ${assignment.Id}`}
            extraText={`${Status_Mapper[assignment.Status.toString().toLowerCase()]}`}
            extraTextClassNames={statusClassNames(assignment.Status)}
            icon={<DetailNavigatorIcon url={assignments_known_urls.assignment_detail} id={assignment.Id} />}
        />
        <div className='rounded border bg-white shadow shadow-t-none'>
            <DetailsLabelValueContainer topBorder fullWidth roundedUp>
                <DetailsLabel align='left' bold label='Assegnata a' />
                <DetailsValue align='left' value={assignment.OwnerEmail} />
            </DetailsLabelValueContainer>

            {assignment.Status === AssignmentStatusEnum.Resolved &&
                <DetailsLabelValueContainer fullWidth>
                    <DetailsLabel align='left' bold label="Motivo Chiusura" />
                    <DetailsValue align='left' value={assignment.ClosureReason} />
                </DetailsLabelValueContainer>
            }

            <DetailsLabelValueContainer fullWidth>
                <DetailsLabel bold label='Descrizione' />
                <DetailsValue align='left' value={assignment.Description} />
            </DetailsLabelValueContainer>
            {/* <div className='h-[10px]' /> */}
            <DetailsLabelValueContainer fullWidth>
                <DetailsLabel bold label='Data Creazione' />
                <DetailsValue align='left' value={assignment.OpeningTime} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='Polo' />
                <DetailsValue align='left' value={assignment.Polo} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='Presidio' />
                <DetailsValue value={assignment.Presidio} />
            </DetailsLabelValueContainer>

            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='Edificio' />
                <DetailsValue value={assignment.BuildingName} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='Codice Immobile' />
                <DetailsValue value={assignment.CRImmobile} />
            </DetailsLabelValueContainer>
            <DetailsLabelValueContainer fullWidth direction='row'>
                <DetailsLabel bold label='Valore' />
                {assignment.SourceType === EticketSourceTypeEnum.SuperMetric && superMetricValue &&
                    <DetailsValue value={superMetricValue?.ActivationValue} />}
                {assignment.SourceType === EticketSourceTypeEnum.Metric && alert &&
                    <DetailsValue value={alert.Value} />}
            </DetailsLabelValueContainer>

            {custom_values.map((custom_value, i) =>
                <DetailsLabelValueContainer fullWidth direction='row'>
                    <DetailsLabel bold label={custom_value.Name} />
                    <DetailsValue value={custom_value.Value} />
                </DetailsLabelValueContainer>
            )}
            <DetailsLabelValueContainer fullWidth direction='row' roundedDown>
                <DetailsLabel bold label='Email inviata' />
                <DetailsValue
                    value={assignment.MailSent ? "Sì" : "No"}
                    noFormat
                    extraIcon={assignment.MailSent ? undefined : <div className='self-end ml-auto'><UnlockAssignmentBtn setAssignment={setAssignment} assignment={assignment} /></div>} />
            </DetailsLabelValueContainer>

        </div>
        {assignment.AssignmentFeedbacks && assignment.AssignmentFeedbacks.length > 0 && <>
            <div style={{ height: 4 }} />
            <div className='rounded border border-gray-400 p-1 bg-white shadow shadow-t-none'>
                {assignment.AssignmentFeedbacks.length > 0 && <Typography style={{ paddingLeft: 6 }} fontWeight={"bold"} align='left' fontSize="1.25rem">Feedbacks:</Typography>}
                {assignment.AssignmentFeedbacks.map((x, i) =>
                    <DetailsLabelValueContainer topBorder={i === 0} roundedUp={i === 0} fullWidth direction='row' roundedDown={i === assignment.AssignmentFeedbacks.length - 1}>
                        <Grid item xs={4} sm={4} className='min-h-[20px] border-r border-r-gray-400'>
                            <div className='flex justify-between items-center f w-[100%] p-1 pr-4'>
                                <p>{formatDate(x.TimeStamp)}</p>
                                <p className='text-lg'>{x.InsertedBy}</p>
                            </div>
                        </Grid>
                        <Grid item xs={8} sm={8} className='h-[100%] min-h-[20px] overflow-hidden' >
                            <div className='flex justify-between items-center f w-[100%] p-1 pl-4'>
                                <p className='text-lg'>{x.Feedback}</p>
                                <p>{x.FeedbackType}</p>
                            </div>
                        </Grid>
                    </DetailsLabelValueContainer>
                )}
            </div>
        </>}
        {reports_links.length > 0 && <>
            <div style={{ height: 4 }} />
            <div className='rounded border border-gray-400 p-1 bg-white shadow shadow-t-none'>
                {reports_links.length > 0 && <Typography style={{ paddingLeft: 6 }} fontWeight={"bold"} align='left' fontSize="1.25rem">Report collegati:</Typography>}
                <Grid container spacing={1} className='flex justify-start px-4'>
                    {reports_links.map((r) => <Grid item xs={12} sm={6}>
                        <Link target="_blank" to={r.Link}>
                            <Typography align='left' className='hover:!bg-gray-200 flex items-center border border-slate-900 !px-4 !my-1' fontSize="1.25rem"><SiPowerbi className='mr-2' />{r.Name}</Typography>
                        </Link>
                    </Grid>
                    )}
                </Grid>

            </div>
        </>}
        {props.showDetails && assignment.SourceType === EticketSourceTypeEnum.Metric && <ExtendedAlertDetailExpandableContainer id={assignment.ExternalEventId} />}
        {props.showDetails && assignment.SourceType === EticketSourceTypeEnum.SuperMetric && <ExtendedSuperMetricValueDetailExpandableContainer id={assignment.ExternalEventId} />}
        {/* <Spacer direction='y' spacing={1} />
        <AssignmentDetailToolbar assignment={assignment} fullWidth /> */}
    </DetailsContainer >
}