import React from 'react'
import { ODataGridProps } from './types'
import { HeaderCell } from '../HeaderCell/HeaderCell'
import { Pager } from '../Pager/Pager'
import { IColumnFilter, IColumnSorting } from '../../types/odata'
import { arraysAreSame } from '../../utility/arrays'
import './styles.css'
import { paginationContext } from '../../store/pager'
import { getPagedOdataItems } from '../../services/generic'
import { CircularProgress } from '@mui/material'
import { isMobile } from 'react-device-detect'
import { IColumnFilterPreset, ISortableHeader } from '../../types/general'
import { useODataGrid } from './hooks/useODataGrid'
import { localStorageResizesItemKey } from '../../store/hooks/useHeaders'
import { useQuery } from '../../utility/useQuery'
import { useUserContext } from '../../../../store/hooks/useUserContext'
export const ODataGrid: React.FC<ODataGridProps> = ({
  hideHeaders,
  headers,
  controllerName,
  expands,
  selectPrimaryKey,
  renderItem,
  enumStoreKey,
  setHeaders,
}) => {
  const query = useQuery();
  // const renderItem = (item:any)=><ODataGridRow item={item} headers={headers}/>
  const { accessToken } = useUserContext();
  const { state,dispatch } = React.useContext(paginationContext);
  React.useEffect(()=>{
    const parsedFilters = JSON.parse(query.get("filters") ??'[]') as IColumnFilter<any>[];
    const parsedPresets = JSON.parse(query.get("presets") ??'[]') as IColumnFilterPreset<any>[];
    
    if(parsedFilters.length > 0 || parsedPresets.length > 0){
      dispatch({
        type:"SET_FILTERS_AND_PRESETS",
        payload:{key:enumStoreKey,filters:parsedFilters,presets:parsedPresets}
      })
    }
  },[query,dispatch, enumStoreKey])
  const {
    sortings: currentSortings,
    filters: currentFilters,
    items: currentItems,
    LAST_CALL_FILTERS: lastCallFilters,
    LAST_CALL_SORTINGS: lastCallSortings,
    isLoading,
    showFilters
  } = state.pagination[enumStoreKey];
  const _load = async (
    skip: number,
    pageSize: number,
    sortings: IColumnSorting<any>[],
    filters: IColumnFilter<any>[]
  ) => {
    if (accessToken) {
      return getPagedOdataItems(controllerName, skip, pageSize, filters, sortings, false, expands)
        .then((res) => {
          const sortingsChanged = !arraysAreSame(
            currentSortings,
            lastCallSortings,
            (
              x: IColumnSorting<any>,
              y: IColumnSorting<any>
            ) =>
              x.key === y.key &&
              x.direction === y.direction &&
              x.innerClass === y.innerClass
          );
          const filtersChanged = !arraysAreSame(
            currentFilters,
            lastCallFilters,
            (
              x: IColumnFilter<any>,
              y: IColumnFilter<any>
            ) =>
              x.columnName === y.columnName &&
              x.value === y.value &&
              x.operator === y.operator
          );
          let result: any[] = filtersChanged ? [] : currentItems;
          if (!sortingsChanged) {
            res.value.forEach((a: any) => {
              if (!result.find((x) => {
                if (!selectPrimaryKey) {
                  return x.Id === a.Id
                } else {
                  return selectPrimaryKey(x) === selectPrimaryKey(a)
                }
              })) {
                result.push(a);
              }
            });
          }
          const result_items = (sortingsChanged || filtersChanged) ? res.value : result;
          return { total_items: res["odata.count"] ?? 0, result_items };
        }
        );
    } else {
      console.log("Access Token not found");
      return { total_items: -1, result_items: [] }
    }
  };
  const setHeaderIsVisible = (h: ISortableHeader<any>, isVisible: boolean) => {
    setHeaders(headers.map((header) => header.id === h.id ? { ...h, isVisible } : header))
  }
  const {
    hiddenHeadersWidth,
    visibleHeaders,
    resizedHeadersWidth,
    headerWidth,
    headerMaxWidth
  } = useODataGrid({headers});
  const [resizeColumnState, setResizeColumnState] = React.useState<{
    active: boolean,
    start: number,
    end: number,
    header?: ISortableHeader<any>
  }>({
    active: false,
    start: -1,
    end: -1
  });
  const startResizeColumn = (e: React.MouseEvent<HTMLDivElement>, header: ISortableHeader<any>) => {
    // e.stopPropagation();
    const pauseEvent = (e:any) => {
      if(e.stopPropagation) e.stopPropagation();
      if(e.preventDefault) e.preventDefault();
      e.cancelBubble=true;
      e.returnValue=false;
      return false;
  }
    pauseEvent(e);
    setResizeColumnState({
      active: true,
      start: e.clientX,
      end: -1,
      header
    })
  }
  const resizeColumn = (state: {
    active: boolean,
    start: number,
    end: number,
    header?: ISortableHeader<any>
  }) => {
    if (!state.header || !state.active) {
      return
    }
    setResizeColumnState({
      active: false,
      start: -1,
      end: -1
    })
    const newHeaders = headers.map((h) => state.header && h.id === state.header.id ? { ...h, resize: state.end - state.start } : h)
    setHeaders(newHeaders)
    localStorage.setItem(`${enumStoreKey}-${localStorageResizesItemKey}`,JSON.stringify(Object.fromEntries(newHeaders.filter((a)=>a.resize).map((h)=>[`${enumStoreKey}-${h.id.toString()}`,h.resize]))))
  }
  const stopResizeColumn = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    resizeColumn({ ...resizeColumnState, end: e.clientX })
  }
  return (<div className="flex flex-col h-[100%] max-h-[100vh] overflow-x-auto" style={{ padding: 2 }}>
    <div className="flex"
      onMouseUp={stopResizeColumn}
      style={{
        // width: `calc(100% - ${state.pagination[enumStoreKey].totalItems > 25 ? '8px' : '0px'})`,
        width: (!isMobile && currentItems && currentItems.length > 10) ?   'calc(100% - 15px)' : 'calc(100% - 3px)',
        marginBottom: 1
      }}>
      {!hideHeaders && visibleHeaders.map((h,i) => (
        <HeaderCell
          headerWidth={headerWidth}
          headerMaxWidth={headerMaxWidth}
          isLast={i === visibleHeaders.length - 1}
          startResize={startResizeColumn}
          stopResize={stopResizeColumn}
          key={`header-${h.id as string}`}
          enumStoreKey={enumStoreKey}
          header={h}
          setHeaderIsVisible={setHeaderIsVisible}
          isLoading={isLoading}
          hiddenHeadersWidth={hiddenHeadersWidth}
          resizedHeadersWidth={resizedHeadersWidth}
          showFilter={showFilters}
        />
      ))}
    </div>
    <Pager
      renderItem={renderItem}
      renderContainer={(
        children: React.ReactElement,
        handleScroll: (e: any) => void
      ) => (
        <div
          style={{
            // height: `calc(100% - ${bottombarHeight}px)` 
            width: window.innerWidth > 1500 ? '100%' : 'calc(100% - 3px)',
            height: '100%'
          }}
          className="flex flex-col overflow-x-hidden overflow-y-autobg-gray-200"
          onScroll={handleScroll}
        >
          {isLoading && <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: isMobile ? 'rgba(0,0,0,0.05)' : 'rgba(140,140,140,0.1)', }}><CircularProgress /></div>}
          {children}
        </div>
      )}
      enumKey={enumStoreKey}
      loadItems={_load}
      items={currentItems}
    />
  </div>)
}