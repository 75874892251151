export type TAction = "UserConfiguration" | "BuildingConfiguration";
export type TRoles = "base" | "advanced" | "admin";
export const sbh_area = 'sbh'

export enum product_urls {
  iot_connected_buildings = "iot-connected-buildings",
  energy = "energy",
  occupancy = "occupancy",
  assetimmobiliare = "dati-immobiliari",
  sbh = 'sbh',
}
//at today typescript version enum with computed string values are not allowed.
// export enum know_urls {
//     iot_dashboard = `/${product_urls.iot_connected_buildings}`,
//     energy_dashboard = `/${product_urls.energy}`,
//     occupancy_dashboard = `/${product_urls.occupancy}`,
//     assetimmobiliare_dashboard = `/${product_urls.assetimmobiliare}`,
//     ticket_connector = `/${product_urls.iot_connected_buildings}/${tc_url}`,
//     ticket_connector_tickets = `/${product_urls.iot_connected_buildings}/${tc_url}/tickets`,
//     ticket_connector_archive = `/${product_urls.iot_connected_buildings}/${tc_url}/archive`,
//     ticket_connector_newticket = `/${product_urls.iot_connected_buildings}/${tc_url}/newticket`,
//     ticket_connector_map = `/${product_urls.iot_connected_buildings}/${tc_url}/map`,
//     ticket_connector_configuration = `/${product_urls.iot_connected_buildings}/${tc_url}/configuration`,
//     report = '/report',
//     users = '/users'
// }
export enum know_urls {
  iot_dashboard = "/iot-connected-buildings",
  energy_dashboard = "/energy",
  occupancy_dashboard = "/occupancy",
  assetimmobiliare_dashboard = "/assetimmobiliare",
  report = "/report",
  // users = "/users",
  user_settings = "/user-settings",
  assignments_settings = "/assignments-settings",
  redirectCallback = "/redirectCallback",
  edit_assets = "/edit-assets",
  edit_dashboard_pages = "/edit-dashboard-pages",
  registration_request_url = "/registration-request",
}
export const known_products = {
  "iot-connected-buildings": {
    id: "iot-connected-buildings",
    dashboard_url: know_urls.iot_dashboard,
  },
  energy: {id: "energy", dashboard_url: know_urls.energy_dashboard},
  occupancy: {id: "occupancy", dashboard_url: know_urls.occupancy_dashboard},
  assetimmobiliare: {
    id: "assetimmobiliare",
    dashboard_url: know_urls.assetimmobiliare_dashboard,
  },
  sbh:{
    id:"sbh",
    dashboard_url:"/",
  }
};
export const special_products: {
  [key: string]: {id: string; dashboard_url: string};
} = {
  sbh: {id: "sbh", dashboard_url: "/"}, //special product
};
export enum known_actions {
  default_values = "/default_values",
  force_closure = "/force_closure",
  force_open = "/force_open",
  edit_assets = "/edit_assets",
  edit_dashboard_pages = "/edit_dashboard_pages",
  main_dashboard_users = "/main_dashboard_users",
  alert_actions = "/alert_actions",
  view_disabled_reports = "/view_disabled_reports",
  view_events = "/view_events",
  edit_product = "/edit_product",
  view_attachments = "/view_attachments",
  edit_other_users = "/edit_other_users",
  edit_broadcast = "/edit_broadcast",
  assignments="/assignments",
  unlock_assignment="/unlock-assignment"
}

export type TAreas =
  | "iot-connected-buildings"
  | "energy"
  | "occupancy"
  | "assetimmobiliare";
export type TAreaRoles = {
  sbh?: TRoles[];
  "iot-connected-buildings"?: TRoles[];
  energy?: TRoles[];
  occupancy?: TRoles[];
  assetimmobiliare?: TRoles[];
};

export interface NeededClaims {
  "/": TAreaRoles;
  [know_urls.user_settings]: TAreaRoles;
  [know_urls.assignments_settings]: TAreaRoles;
  [know_urls.iot_dashboard]: TAreaRoles;
  [know_urls.energy_dashboard]: TAreaRoles;
  [know_urls.occupancy_dashboard]: TAreaRoles;
  [know_urls.assetimmobiliare_dashboard]: TAreaRoles;
  [know_urls.report]: TAreaRoles;
  [know_urls.edit_assets]: TAreaRoles;
  [know_urls.edit_dashboard_pages]: TAreaRoles;
  [known_actions.force_closure]: TAreaRoles;
  [known_actions.force_open]: TAreaRoles;
  [known_actions.edit_assets]: TAreaRoles;
  [known_actions.main_dashboard_users]: TAreaRoles;
  [known_actions.alert_actions]: TAreaRoles;
  [known_actions.default_values]: TAreaRoles;
  [known_actions.view_disabled_reports]: TAreaRoles;
  [known_actions.edit_product]: TAreaRoles;
  [known_actions.view_attachments]: TAreaRoles;
  [known_actions.edit_other_users]:TAreaRoles;
  [known_actions.edit_broadcast]:TAreaRoles;
  [known_actions.assignments]:TAreaRoles;
  [known_actions.view_events]:TAreaRoles;
  [known_actions.unlock_assignment]:TAreaRoles;
}
