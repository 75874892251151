import { InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import axios from "axios";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { apiLoginRequest } from "../../authConfig";
import { GetUserInfo, ICustomClaims } from "../../services/user";
import { BuildingSettings } from "../../types/Building";
import { listValuesAreEquals } from "../../utility/arrays";
import { useUserContext } from "./useUserContext";
import { NeededClaims, TAreas, TRoles, known_actions, know_urls, known_products, sbh_area, special_products } from "../../types/userStore";
import { useGlobalStore } from "./useGlobalStore";
import { IGenericError } from "../../types/general";

const roles: TRoles[] = ["base", "advanced", "admin"]
const advanced_roles: TRoles[] = ["advanced", "admin"]
const admin_roles: TRoles[] = ["admin"]

const neededClaims: NeededClaims = {
    '/': { [sbh_area]: roles },
    [known_actions.default_values]: { [sbh_area]: admin_roles },
    [known_actions.edit_assets]: { [sbh_area]: admin_roles },
    [know_urls.edit_dashboard_pages]: { [sbh_area]: admin_roles },
    [known_actions.main_dashboard_users]: { [sbh_area]: admin_roles },
    [known_actions.alert_actions]: { "iot-connected-buildings": advanced_roles },
    [known_actions.force_closure]: { "iot-connected-buildings": advanced_roles },
    [known_actions.force_open]: { "iot-connected-buildings": advanced_roles },
    [know_urls.user_settings]: { [sbh_area]: roles },
    [know_urls.assignments_settings]: { [sbh_area]: roles },
    [know_urls.iot_dashboard]: { "iot-connected-buildings": roles },
    [know_urls.report]: { [sbh_area]: roles },
    [know_urls.energy_dashboard]: { "energy": roles },
    [know_urls.occupancy_dashboard]: { "occupancy": roles },
    [know_urls.assetimmobiliare_dashboard]: { "assetimmobiliare": roles },
    [know_urls.edit_assets]: { [sbh_area]: admin_roles, 'iot-connected-buildings': admin_roles },
    [known_actions.view_disabled_reports]: { [sbh_area]: advanced_roles, },
    [known_actions.edit_product]: { [sbh_area]: admin_roles, },
    [known_actions.view_attachments]: { [sbh_area]: admin_roles, },
    [process.env.REACT_APP_TICKET_CONNECTOR_URL ?? '']: { [sbh_area]: admin_roles, 'iot-connected-buildings': admin_roles },
    [known_actions.edit_other_users]: { [sbh_area]: admin_roles },
    [known_actions.edit_broadcast]: { [sbh_area]: admin_roles },
    [known_actions.assignments]: { [sbh_area]: admin_roles },
    [known_actions.view_events]: { [sbh_area]: admin_roles },
    [known_actions.unlock_assignment]: { [sbh_area]: admin_roles },

}
export function hasPermission(claims: ICustomClaims[], uri: keyof NeededClaims, areaId: ('sbh' | TAreas) = sbh_area) {
    try {
        if (!claims || claims.length === 0) { return false }
        if(uri.startsWith("/assignments")){
            return true; //assignments permissions are demanded to assignment app
        }
        const _allAreaId = sbh_area;
        let _areaId = areaId === special_products.sbh.toString() ? _allAreaId : areaId;
        const _neededClaims = neededClaims[uri][_areaId] ?? [];
        const _permissions = _areaId !== _allAreaId ? claims.find((p) => p.ProductId === _areaId) : claims.find(p => p.ProductId === _allAreaId);
        if (!_permissions) {
            console.log("redirecting")
            return false;
        }
        return _neededClaims.some((p) => _permissions.RuoloId === p)
    } catch {
        return false;
    }
}
export const useUserStore = () => {
    //only call this one time, from a permanent component
    const { userState, dispatch, setUserInfo, setUserClaims, setAccessToken, setBuildingSettings, setUserImage, setGraphAccessToken } = useUserContext();
    const { instance, accounts, inProgress } = useMsal();
    const { setGlobalError } = useGlobalStore();
    const navigate = useNavigate();
    const location = useLocation();
    const isAuthenticated = useIsAuthenticated();
    const setAxiosInterceptors = (token: string) => {
        axios.interceptors.request.use((config) => {
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        },
            (error) => Promise.reject(error)
        )
        axios.interceptors.response.use(function (response) {
            // Any status code that lie within the range of 2xx cause this function to trigger
            // Do something with response data
            return response;
        }, function (error) {
            console.log(error)
            // Reject promise if usual error
            if (error.response.status !== 401) {
                setGlobalError(error as IGenericError)
                return Promise.reject(error);
            }
            instance.loginRedirect(apiLoginRequest)
                .then(() =>
                    instance.acquireTokenSilent(apiLoginRequest).then((response) => {
                        setAccessToken(response.accessToken)
                    }))
                .catch(e => {
                    console.log(e)
                });

        });
    }
    const tryGetToken = async () => {
        if (inProgress === InteractionStatus.None && isAuthenticated && accounts.length > 0) {
            if (!userState.userInfo.accessToken) {
                instance.setActiveAccount(accounts[0])
                instance.acquireTokenSilent(apiLoginRequest).then((response) => {
                    setAxiosInterceptors(response.accessToken);
                    const payload = response.account ? { ...userState.userInfo, accessToken: response.accessToken, account: response.account } : { ...userState.userInfo, accessToken: response.accessToken };
                    setUserInfo(payload);
                })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    }

    React.useEffect(() => {
        if (!userState.userInfo.accessToken) {
            tryGetToken()
        }
    }, [isAuthenticated, inProgress, userState.userInfo.accessToken])

    const area = React.useMemo(() => {
        const area_urls = Object.values(known_products).map((x) => x.dashboard_url.slice(1));
        const area_ids = Object.values(known_products).map((x) => x.id);
        let _ = document.URL.split('/')[3];
        if (_.includes('?')) {
            _ = _.slice(0, _.indexOf('?'))
        }
        const area_id = area_ids[area_urls.findIndex(x => x === _)]
        return area_id ?? undefined;
    }, [location.pathname])

    React.useEffect(() => {
        if (inProgress === InteractionStatus.None && isAuthenticated && userState.userInfo.accessToken) {
            GetUserInfo().then(userInfo => {
                if (!listValuesAreEquals(userState.userInfo.customClaims, userInfo.Abilitazioni, (x1, x2) => x1.ProductId === x2.ProductId && x1.RuoloId === x2.RuoloId)) {
                    setUserClaims(userInfo.Abilitazioni);
                }
                if (!hasPermission(userInfo.Abilitazioni, location.pathname as keyof NeededClaims, area ? area as unknown as TAreas : sbh_area)) {
                    const baseUrl = `/`
                    navigate(baseUrl);
                } else {
                    const settings = JSON.parse(userInfo.Settings) as any;
                    const buildingSettings = JSON.parse(settings.DefaultBuildingSettings) as BuildingSettings;
                    setBuildingSettings(buildingSettings);
                }
            })
        }
    }, [location.pathname, isAuthenticated, inProgress, userState.userInfo.accessToken])



    return {
        userState: userState,
        userInfo: userState.userInfo,
        userImage: userState.userImage,
        userClaims: userState.userInfo.customClaims,
        accessToken: userState.userInfo.accessToken,
        account: userState.userInfo.account,
        buildingSettings: userState.buildingSettings,
        dispatch,
        setUserInfo,
        setUserClaims,
        setAccessToken,
        setBuildingSettings,
        setUserImage,
        setGraphAccessToken,
    }
}