import React from 'react'
import { ISortableHeader } from '../../types/general'
import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'

import { columnNamesLangMap } from '../FilterChips/FilterChips'
import { PaginationEnum } from '../../store/pager'
import { localStorageHidesItemKey } from '../../store/hooks/useHeaders'
type PROPS = {
    headers: ISortableHeader<any>[]
    setHeaders: (headers: ISortableHeader<any>[]) => void
    enumKey:PaginationEnum
}
export const HiddenHeadersSelect: React.FC<PROPS> = ({ headers, setHeaders,enumKey }) => {
    const setHeaderVisible = (h: ISortableHeader<any>, isVisible: boolean) => {
        const newHeaders = headers.map((header) => header.id === h.id ? { ...h, isVisible } : header)
        setHeaders(newHeaders)
        localStorage.setItem(`${enumKey}-${localStorageHidesItemKey}`, JSON.stringify(Object.fromEntries(newHeaders.map((h) => [`${enumKey}-${h.id.toString()}`, h.isVisible]))))

    }
    return <FormControl className="!my-2 !py-2">
        <InputLabel id="hidden-headers-select-label">Mostra/Nascondi colonne</InputLabel>
        <Select
            multiple
            MenuProps={{
                style: {zIndex: 999999999}
            }}
            value={[]}
            sx={{ width: 250, height: 40 }}
            id="hidden-headers-select-label"
            label="Mostra/Nascondi colonne"
        >
            {headers.map((h) => <MenuItem
            style={{zIndex:99999999}}
                onClick={() => setHeaderVisible(h, !h.isVisible)}
            >
                <Checkbox checked={h.isVisible} />
                <ListItemText primary={columnNamesLangMap[h.id.toString().toLowerCase()].toUpperCase()} />

            </MenuItem>)}
        </Select>
    </FormControl>
}