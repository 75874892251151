import React from 'react'
import { AssignmentFeedbackTypeEnum, IAssignmentFeedback, ISuperMetricConfiguration } from '../../../types/etickets'
import { Button, Dialog, FormControl, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Snackbar, Typography } from '@mui/material'
import { AssignmentStatusEnum, TAssignment } from '../../../types/assignments'
import { assignmentFeedback, resolveAssignment } from '../../../services/assignments'
import { getSuperMetricConfiguration } from '../../../services/supermetric'
import { useSidebars } from '../../../store/hooks/useSidebars'
import { intesa_green } from '../../../../../themes/themes'
import { useUserContext } from '../../../../../store/hooks/useUserContext'
import { ICustomClaims } from '../../../services/user'
import { paginationContext, PaginationEnum } from '../../../store/pager'
import { LightTooltip } from '../../LightTooltip/LightTooltip'
import './styles.css'

type Props = {
    assignment: TAssignment
    fullWidth?: boolean
    setAssignment: (x: TAssignment) => void

}
export const SuperMetricDetailToolbar: React.FC<Props> = ({ assignment, fullWidth, setAssignment }) => {
    const { isMobile } = useSidebars();
    const { account, userClaims } = useUserContext();
    const [snackbar, setSnackBar] = React.useState({ open: false, msg: "" });
    const sbhAdminClaim: ICustomClaims = { ProductId: "sbh", RuoloId: 'admin' }
    const isAdmin = userClaims.find((x) => x.ProductId === sbhAdminClaim.ProductId && x.RuoloId === sbhAdminClaim.RuoloId) !== undefined;
    const [openConfirm, setOpenConfirm] = React.useState(false)
    const [tooltipDescr, setTooltipDescr] = React.useState('');
    const { state: paginationState, dispatch: paginationDispatch } = React.useContext(paginationContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const [assignmentConfiguration, setAssignmentConfiguration] = React.useState<ISuperMetricConfiguration>()
    const notResolvableReasons = {
        NotOwner: {
            val: account.username.toLowerCase() !== assignment.OwnerEmail.toLowerCase(),
            descr: "Solo l'owner può risolvere l'assegnazione"
        },
        AlreadyResolved: {
            val: assignment.Status === AssignmentStatusEnum.Resolved,
            descr: "Assegnazione già risolta"
        }
    }
    const notResolvable = React.useMemo(() => {
        // return false;
        const reasons = Object.values(notResolvableReasons);
        let tooltips: string[] = []
        let result = false;
        for (let i = 0; i < reasons.length; i++) {
            if (reasons[i].val) {
                tooltips.push(reasons[i].descr)
                result = true;
            }
        }
        setTooltipDescr(tooltips.join("\n"))
        return result;
    }, [assignment, account])
    React.useEffect(() => {
        getSuperMetricConfiguration(assignment.ExternalReferenceId)
            .then((cfg) => setAssignmentConfiguration(cfg))
            .catch((err) => console.log(err))

    }, [assignment.OwnerEmail, assignment.ExternalReferenceId])
    const [ttlDays, setTTLDays] = React.useState(0);
    const [reason, setReason] = React.useState<string | null | undefined>(assignmentConfiguration?.ClosureReason ? assignmentConfiguration.ClosureReason[0] : null)
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        if (feedback && feedback.AssignmentFeedbackType === AssignmentFeedbackTypeEnum.Deroga) {
            setTTLDays(0)
        }
        setReason(e.target.value)
    }

    const feedback = React.useMemo(() => {
        const defaultFeedback: IAssignmentFeedback = {
            Id: -1,
            SuperMetricId: assignment.ExternalReferenceId,
            Text: "",
            AssignmentFeedbackType: AssignmentFeedbackTypeEnum.Closure
        }
        if (assignmentConfiguration && reason) {
            return assignmentConfiguration.AllowedFeedback.length > 0 ? assignmentConfiguration.AllowedFeedback.find((x) => x.Text === reason) : defaultFeedback
        } else {
            return undefined;
        }
    }, [assignmentConfiguration, reason]);
    const validateFeedback = (feedback: IAssignmentFeedback | undefined) => {
        if (!feedback) {
            return false;
        }
        if (feedback.AssignmentFeedbackType === AssignmentFeedbackTypeEnum.Closure) {
            return true;
        } else if (feedback.AssignmentFeedbackType === AssignmentFeedbackTypeEnum.Deroga) {
            return ttlDays > 0
        }
        else if (feedback.AssignmentFeedbackType === AssignmentFeedbackTypeEnum.Info) {
            return true
        }
        else {
            return false;
        }
    }
    const handleClick = () => {
        if (assignmentConfiguration && reason) {
            if (feedback) {
                validateFeedback(feedback)
                setIsLoading(true);
                assignmentFeedback(assignment.Id, reason, ttlDays, feedback.AssignmentFeedbackType)
                    .then((res) => {
                        setAssignment(res)
                        // setSnackBar({ open: true, msg: feedback.AssignmentFeedbackType === AssignmentFeedbackTypeEnum.Closure ? "Assegnazione chiusa correttamente" : "Feedback inviato correttamente" })
                        setSnackBar({ open: true, msg: "Feedback inviato correttamente" })
                    })
                    .finally(() => setIsLoading(false))
            }

        }
    }
    const handleAdminBtnClick = () => {
        setIsLoading(true);
        const adminClosureReason = "Chiuso da admin"
        resolveAssignment(assignment.Id, adminClosureReason)
            .then((res) => {
                setAssignment(res)
                setSnackBar({ open: true, msg: "Assegnazione chiusa correttamente" })
                setOpenConfirm(false)
            })
            .finally(() => setIsLoading(false))
    }
    const submitBtnText = React.useMemo(() => {
        if (!feedback) {
            return "Invia"
        }
        switch (feedback.AssignmentFeedbackType) {
            case AssignmentFeedbackTypeEnum.Deroga: return "Invia deroga"
            case AssignmentFeedbackTypeEnum.Info: return "Invia feedback"
            case AssignmentFeedbackTypeEnum.Closure: return "Risolvi"
            default: return "Invia"
        }
    }, [feedback])
    const label = "Motivazione Chiusura"
    const tooltipWrap = (children: React.ReactElement) => tooltipDescr ? <LightTooltip arrow title={tooltipDescr} >{children}</LightTooltip> : children
    const feedbackTTLDaysOptions = [
        { value: 10, text: "10 giorni" },
        { value: 20, text: "20 giorni" },
        { value: 30, text: "30 giorni" },
    ]
    const renderTTLDaysControl = React.useCallback(() => {
        if (!feedback) {
            return null;
        }
        if (feedback && feedback.AssignmentFeedbackType === AssignmentFeedbackTypeEnum.Deroga) {
            return <FormControl variant="outlined" style={{ minWidth: 50, height: 60 }}>
                <InputLabel htmlFor={"supermetricdetail-toolbar-ttldays-select"}>Deroga</InputLabel>
                <Select
                    disabled={isLoading || notResolvable}
                    id="supermetricdetail-toolbar-ttldays-select"
                    label={label}
                    value={ttlDays}
                    input={
                        <OutlinedInput
                            size='small'
                            style={{ height: 60, minWidth: 100, backgroundColor: 'white', border: '1px solid rgb(200,200,200)', borderRadius: 0 }}
                            value={ttlDays}
                            onChange={(e) => setTTLDays(parseInt(e.target.value))}
                            type="number" />
                    }

                >
                    {feedbackTTLDaysOptions.map((opt) => <MenuItem value={opt.value}>{opt.text}</MenuItem>)}
                </Select>

            </FormControl>
        }
    }, [feedback, ttlDays])
    if (isLoading) {
        return <LinearProgress style={{ color: intesa_green, width: 100, height: 10, marginTop: 20 }} />
    }
    if (!assignmentConfiguration) {
        return <p className='text-lg text-center'>Nessuna closure reason configurata.</p>
    }

    return <div className='rounded border border-gray-400 shadow p-1 bg-gray-100'>
        {assignmentConfiguration && <div className='py-2 px-4' style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p className='text-xl text-center'>
                {assignmentConfiguration && assignmentConfiguration.IsAutomaticClose ?
                    `Se ritieni che questa segnalazione sia già stata gestita o non sia rilevante/corretta ti chiediamo di risolvere il caso ${assignmentConfiguration.AllowedFeedback.length == 0 ? "inserendo un commento" : "selezionando una risposta"}.`
                    :
                    `Questa assegnazione si chiuderà in automatico quando il problema che l'ha generata sarà risolto. Puoi lasciare un feedback ${assignmentConfiguration && assignmentConfiguration.AllowedFeedback.length == 0 ? "inserendo un commento" : "selezionando una risposta"}.`
                }
            </p>
            <div className='mb-1' />
            <div className={`flex ${isMobile ? "flex-col items-center" : "flex-row items-end justify-end"} w-[100%] `}>

                <FormControl variant="outlined" style={{ minWidth: 200, height: 60 }}>
                    <InputLabel htmlFor={"supermetricdetail-toolbar-select"} className='outlined-input-label'>{label}</InputLabel>
                    {assignmentConfiguration.AllowedFeedback.length === 0
                        ?
                        tooltipWrap(<OutlinedInput
                            // style={{ borderRadius: 0 }}
                            className='outlined-input'
                            id={"supermetricdetail-toolbar-select"}
                            label={label}
                            onChange={handleChange}
                        />)
                        :
                        tooltipWrap(<Select
                            disabled={isLoading || notResolvable}
                            id="supermetricdetail-toolbar-select"
                            label={label}
                            input={<OutlinedInput
                                // style={{ borderRadius: 0 }}
                                className='outlined-input'
                                id={"supermetricdetail-toolbar-select"}
                                label={label}
                                onChange={handleChange}
                            />}
                        >
                            {assignmentConfiguration.AllowedFeedback.map((feedback) => <MenuItem value={feedback.Text}>{feedback.Text}</MenuItem>)}
                        </Select>)
                    }
                </FormControl>
                <div className={isMobile ? "mt-2" : 'ml-4'} />
                {renderTTLDaysControl()}
                <div className='ml-4' />
                <Button
                    style={{ height: 60, width: 120, borderRadius: 2 }}
                    variant='contained'
                    disabled={!reason || isLoading || notResolvable || !validateFeedback(feedback)}
                    onClick={handleClick}
                >
                    {submitBtnText}
                </Button>
                <div className={isMobile ? "mt-2" : 'ml-4'} />
                {isAdmin && <Button
                    style={{ height: 60, width: 120, borderRadius: 2 }}
                    variant='contained'
                    disabled={!isAdmin || isLoading}
                    onClick={() => setOpenConfirm(true)}
                >
                    Forza Chiusura
                </Button>}
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={() => setSnackBar({ open: false, msg: '' })}
                    message={snackbar.msg}
                />
            </div>
            <Dialog open={openConfirm} onClose={() => setOpenConfirm(false)}>
                <div className="flex flex-col min-h-[150px] p-2">
                    <Typography>Sei sicuro di voler forzare la chiusura?</Typography>
                    <div className="mt-auto" />
                    <div className="flex w-[100%] justify-between">
                        <Button variant="outlined" onClick={() => setOpenConfirm(false)}>Annulla</Button>
                        <Button variant="contained" onClick={handleAdminBtnClick}>Conferma</Button>
                    </div>
                </div>
            </Dialog>
        </div>}
        {/* </DetailsContainer> */}
    </div>

}