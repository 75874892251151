import React from "react";
import { FilterPresetsIcon } from "../../FilterPresetsIcon/FilterPresetsIcon";
import { PaginationEnum, paginationContext } from "../../../store/pager";
import { Typography } from "@mui/material";
import { HiddenHeadersSelect } from "../../HiddenHeadersSelect/HiddenHeadersSelect";
import { ISortableHeader } from "../../../types/general";
import { ResetResizeAndHidesButton } from "./ResetResizeAndHidesButton/ResetResizeAndHidesButton";
import { useIsMobile } from "../../../utility/useIsMobile";
import { ExportFilteredGridButton } from "./ExportFilteredGridButton/ExportFilteredGridButton";
import { ExportExcelGridButton } from "./ExportExcelGridButton/ExportExcelGridButton";
export type ODataGridToolbarProps = {
  enumStoreKey: PaginationEnum;
  headers: ISortableHeader<any>[]
  setHeaders: (headers: ISortableHeader<any>[]) => void
};
export const ODataGridToolbar: React.FC<ODataGridToolbarProps> = ({
  enumStoreKey,
  headers,
  setHeaders,
}) => {
  const { state } = React.useContext(paginationContext);
  const { isLoading, totalItems } = state.pagination[enumStoreKey];
  const {isMobile} = useIsMobile();
  return (
    <div
      className="flex flex-row items-center"
      style={{ width: "calc(100% - 10px)", minHeight: 60 }}
    >
      {/* <Box className="flex items-center w-[90%] h-[80px]"> */}
      <FilterPresetsIcon enumKey={enumStoreKey} style={{ marginLeft: 15 }} />
      <div className="!ml-auto" />
      {!isMobile && <HiddenHeadersSelect enumKey={enumStoreKey} headers={headers} setHeaders={setHeaders} />}
      <div className="!ml-1" />
      <ExportExcelGridButton enumKey={enumStoreKey} />
      <div className="!ml-1" />
      <ExportFilteredGridButton enumKey={enumStoreKey} />
      
      <div className="!ml-1" />
      {/* </Box> */}
      {!isMobile &&<ResetResizeAndHidesButton enumKey={enumStoreKey} />}
      <div className="!ml-1" />
      {!isLoading && <Typography fontSize={"1.05rem"} style={{ marginRight: 15 }}>
        ({totalItems} elementi)
      </Typography>}
    </div>
  );
};
