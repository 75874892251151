import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import { AssignmentStatusEnum, TAssignment } from '../../../../types/assignments'
import { RxResume } from 'react-icons/rx'
import { unlockAssignment } from '../../../../services/assignments'
import { PaginationEnum, paginationContext } from '../../../../store/pager'
import { useUserContext } from '../../../../../../store/hooks/useUserContext'
type PROPS = {
    assignment: TAssignment
    setAssignment?: (x: TAssignment) => void
}
const sbh_productId = 'sbh'
export const UnlockAssignmentBtn: React.FC<PROPS> = ({ assignment, setAssignment }) => {
    const { userClaims } = useUserContext();
    const hasUnlockAuth = React.useMemo(() => userClaims.find(x => x.ProductId === sbh_productId)?.RuoloId === 'admin', [userClaims]);
    const { state: paginationState, dispatch: paginationDispatch } = React.useContext(paginationContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const handleUnlockAssignment = React.useCallback(async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, a: TAssignment) => {
        e.stopPropagation();
        setIsLoading(true);
        unlockAssignment(a.Id)
            // console.log("Unlocking")
            .then((res) => {
                if (setAssignment) {
                    setAssignment(res)
                } else {
                    paginationDispatch({
                        type: "SET_PAGINATION",
                        payload: {
                            key: PaginationEnum.ASSIGNMENTS,
                            pagination: {
                                ...paginationState.pagination.ASSIGNMENTS,
                                items: paginationState.pagination.ASSIGNMENTS.items.map((x) => x.Id === assignment.Id ? res : x)
                            }
                        }
                    })
                }

            })
            .finally(() => { setIsLoading(false); setOpenDialog(false) })
    }, [assignment.Id, paginationDispatch, paginationState.pagination.ASSIGNMENTS])
    const [openDialog, setOpenDialog] = React.useState(false);
    if (!hasUnlockAuth || assignment.MailSent) {
        return null;
    }
    return <div
        style={{
            width: '100%',
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
        }}

        onClick={(e) => e.stopPropagation()}>
        <IconButton  
            className='hover:!bg-gray-100 !rounded-none'
            disabled={!hasUnlockAuth}
            style={{
                width: 35,
                height: 35,
                fontSize: '2rem',

            }}
            onClick={(e) => {
                e.stopPropagation();
                setOpenDialog(true);
            }}>
            <RxResume />
        </IconButton>
        <Dialog
            style={{ zIndex: 999999999 }}
            open={openDialog}
            onClose={(e) => {
                if (!isLoading) {
                    setOpenDialog(false)
                }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Sblocca Assignment"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Sei sicuro di voler sbloccare l'assignment {assignment.Id}?
                </DialogContentText>
                {isLoading && <DialogContentText><CircularProgress style={{ position: 'absolute', right: 15, top: 15, width: 30, height: 30, color: 'black' }} /></DialogContentText>}
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={isLoading}
                    onClick={(e) => {
                        e.stopPropagation();
                        setOpenDialog(false)
                    }}>Annulla</Button>
                <Button disabled={isLoading} onClick={(e) => {
                    handleUnlockAssignment(e, assignment)
                }} autoFocus>
                    Conferma
                </Button>
            </DialogActions>
        </Dialog>
    </div>
}